module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.14.0_gatsby-plugin-sharp@5.14.0_gatsby@5.14.0/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":672,"backgroundColor":"transparent","withWebp":true,"wrapperStyle":"margin: 0 auto;","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Haloroundmyhead Knits","short_name":"HaloKnits","description":"Blog and pattern portfolio for knitting designer Sarah Ellis","start_url":"/","background_color":"#111b1f","theme_color":"#253435","display":"minimal-ui","icon":"static/img/yarnballcat-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"26ea85efd08b748aba84bd1435b4273a"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.14.0_gatsby@5.14.0_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/img*"]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-decap-cms@4.0.4_webpack@5.96.1/node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","enableIdentityWidget":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.6.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
